import React from 'react';
// import CogNac from '@/assets/cognac.png';
import { StaticImage } from 'gatsby-plugin-image';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import 'swiper/css';

function ConceptSection() {
  return (
    <div className="flex items-end w-full fmin-h-screen">
      <div className="container p-6 mx-auto">
        <div className="self-center mx-auto w-fit">
          <h2 className="my-3 text-4xl font-bold uppercase text-santis-purple">
            The beaver <span className="text-santis-gold">concept</span>
          </h2>
          <p className="text-lg max-w-[1000px]">
            Beavers are ecological engineers and keystone players in an
            ecosystem. They provide essential services that allow others to
            thrive. At Santis, we offer beaver services to our clients by
            providing premium concierge services and navigating the complex
            healthcare industry giving our clients peace of mind.
          </p>
        </div>

        <Swiper
          className="w-full mt-10 "
          loop={true}
          slidesPerView={6}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              width: 320,
              slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 4,
            },
          }}
          modules={[Autoplay]}
        >
          <SwiperSlide>
            <StaticImage
              objectFit="contain"
              className="flex-shrink-0 w-40"
              alt=""
              src="../assets/bupa.png"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              objectFit="contain"
              className="flex-shrink-0 w-40"
              alt=""
              src="../assets/cigna.png"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              objectFit="contain"
              className="flex-shrink-0 w-40"
              alt=""
              src="../assets/gbg.png"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              objectFit="contain"
              className="flex-shrink-0 w-40"
              alt=""
              src="../assets/solice.jpeg"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              objectFit="contain"
              className="flex-shrink-0 w-40"
              alt=""
              src="../assets/the-luxury-network.png"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              objectFit="contain"
              className="flex-shrink-0 w-40"
              alt=""
              src="../assets/paelon.png"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              objectFit="contain"
              className="flex-shrink-0 w-40"
              alt=""
              src="../assets/euracare.png"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default ConceptSection;
